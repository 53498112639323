import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

interface IMediaItem {
  publication: string
  date: string
  link: string
}

const Media = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityMedia {
        id
        title
        media1 {
          publication
          link
        }
      }
    }
  `)
  const { title, media1 } = data.sanityMedia

  return (
    <Layout>
      <SEO title={title} />
      <div className="container-theme">
        <div className="container-fluid media">
          <div className="row justify-content-end">
            <div className="col-5 col-6-mob">
              <h1 className="media-title">{title}</h1>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-5 col-6-mob">
              <article className="media-list gm-list">
                <div className="gm-list-header row">
                  <div className="col-list-left text-sm">
                    <span className="gm-list-heading">Publication</span>
                  </div>
                  <div className="col-list-right text-sm">
                    <span className="gm-list-heading">Link</span>
                  </div>
                  <div className="col-list-divider" />
                </div>
                {media1.map((p: IMediaItem, i: number) => (
                  <div className="gm-list-content row" key={i}>
                    <div className="col-list-left my-auto">
                      <h2 className="gm-list-title text-std">
                        {p.publication}
                      </h2>
                    </div>
                    <div className="col-list-right my-auto">
                      <a
                        href={p.link}
                        target="_blank"
                        className="gm-list-link text-std"
                      >
                        View
                      </a>
                    </div>
                    <div className="col-list-divider" />
                  </div>
                ))}
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Media
